import React, { forwardRef } from "react";
import Radium from "radium";
import { Button } from "react-bootstrap";
import * as C from "../Util/constants";
import TechnologiesList from "./TechnologiesList";
function ProjectCard(props, ref) {
  const template = props.template;
  var styles = {
    container: {
      width: "100%",
      minHeight: "300px",
      minWidth: "300px",
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      boxShadow: "4px 8px 4px 4px rgba(74, 74, 74, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderRadius: "5px",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      height: "100px",
      backgroundColor: template.color,
    },
    title: {
        margin: 0,
        display: "flex",
        alignItems: "center",
      },
  };

  return (
    <div style={styles.container} ref={ref}>
      <div className="p-3" style={[styles.header]}>
        <div
          className="p-2"
          style={{
            height: "100%",
            backgroundColor: "white",
            flex: 1,
            maxWidth: "75%",
            display: "flex",
            gap: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: "100%" }}
            src={require(`../assets/${template.iconName}`)}
          ></img>
          <h1 style={[styles.title, template.titleStyle]}> {template.title} </h1>
        </div>
        <div className="d-grid gap-2" style={ { display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px" }}>
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => {
              window.open(`${template.github}`, "_blank");
            }}
          >
            Github
          </Button>
          {template.demo && <Button
            variant="outline-dark"
            size="sm"
            onClick={() => {
              window.open(`${template.demo}`, "_blank");
            }}
          >
            Demo
          </Button>}
          {template.report && <Button
            variant="outline-dark"
            size="sm"
            onClick={() => {
              window.open(`${template.report}`, "_blank");
            }}
          >
            Report
          </Button>}
        </div>
      </div>
      <div
        className="mt-2 p-3"
        style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", flex: 1 }}
      >
        {template.body}
        <TechnologiesList section={"projects"} technologies={template.technologies}></TechnologiesList>
      </div>
    </div>
  );
}

export default Radium(React.forwardRef(ProjectCard));
