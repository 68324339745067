import React from 'react'

export default function WorkIcon(props) {
    let image = require(`../assets/${props.imageName}`)

    return (
        <img src={image} style={{maxWidth: "100%", maxHeight: "100%", borderRadius: "50%"}}>
            
        </img>
    )
}
