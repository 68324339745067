import React, { useEffect, useMemo } from "react";
import Radium from "radium";
import * as C from "../Util/constants";
import Title from "../Components/Title";
import ProjectCard from "../Components/ProjectCard";
function Projects(props, ref) {
  useMemo(() => {
    for (const projectName of Object.keys(projectTemplates)) {
      let ref = React.createRef();
      projectTemplates[projectName]["ref"] = ref;
    }
  }, []);
  useEffect(() => {
    let threshold = [];
    for (let i = 0; i <= 1; i += 0.04) {
      threshold.push(i);
    }
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: threshold,
    };
    let callback = (entries, observer) => {
      entries.forEach((entry) => {
        entry.target.style.opacity = entry.intersectionRatio;
      });
    };
    let observer = new IntersectionObserver(callback, options);
    for (const projectName of Object.keys(projectTemplates)) {
      observer.observe(projectTemplates[projectName]["ref"].current);
    }
  }, []);

  const createProjectCard = ([projectName, projectTemplate]) => {
    return <ProjectCard key={projectName} template={projectTemplate} ref={projectTemplate.ref}></ProjectCard>;
  };
  return (
    <div className="mt-3" id={props.section} ref={ref} style={[{ minHeight: "100%", width: "100%" }]}>
      <Title title="Projects" subtitle=" Some things I wanted that didn’t exist but now exist" section={props.section}></Title>
      <div className="my-5" style={[C.centerFlex, { width: "100%" }]}>
        <div style={[C.centerFlex, { width: "700px", maxWidth: "100%", gap: "60px" }]}>
          {Object.entries(projectTemplates).map(createProjectCard)}
        </div>
      </div>
    </div>
  );
}

var sectionColors = C.sectionTemplates["projects"].color;
var styles = {
  architextLetter: {
    width: "40px",
    height: "40px",
    outline: "2px solid black",
    backgroundColor: "white",
    fontSize: "1rem",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    fontFamily: "'Roboto Mono', monospace",
  },
  h: { color: "#6254b3" },
  u: { textDecoration: `underline ${sectionColors.selected}` },
};

var projectTemplates = {
  mimi: {
    iconName: "mimi_icon.jpg",
    title: "MiMi",
    titleStyle: { fontFamily: "Urbanist", color: "red" },
    color: "#DD5152",
    github: "https://github.com/zamandalee/mimi-extension",
    report: "https://docs.google.com/document/d/1zkW6VRfYeI11-wxBmcRcHk8kn76za2uGmteg4EL_T9o/edit?usp=sharing",
    body: (
      <p>
        <span style={styles.h}>
          An easy-to-use, server-optional password manager that generates encrypted passwords with 2FA guarantees.
        </span>{" "}
        MiMi protects against common password attacks, including brute force, keylogging, phishing, and data breaches. It generates
        passwords by hashing a seed string that consists of the plaintext user password, domain name, and client token. When the user opts
        to enable the password server, a site-specific random counter token is also concatenated to the seed string. MiMi protects user
        passwords even when they are compromised by an attacker. For more details about the threat model and security guarantees, check out the report linked above. 
      </p>
    ),
    technologies: ["React", "Firebase", "Chrome"],
  },
  architext: {
    iconName: "architext_icon.png",
    title: "ARCHITEXT",
    titleStyle: { fontFamily: "Roboto Mono", color: "gray" },
    color: "#A4CEF1",
    github: "https://github.com/masonzhang21/architext",
    demo: "https://architext.masonzee.com/",
    body: (
      <p>
        <span style={styles.h}>A free, collaborative, web-based crossword puzzle constructor.</span> Designed for collaborative crossword
        constructing, Architext will instantaneously sync edits like a Google Doc and store progress the cloud. It enforces grid symmetry
        and suggests possible words based on the current state of the grid.
      </p>
    ),
    technologies: ["React", "Firebase"],
  },
  timewarden: {
    iconName: "timewarden_icon.png",
    title: "Time Warden",
    titleStyle: { fontFamily: "Rowdies", color: "#DAA520" },
    color: "#f5be33",
    github: "https://github.com/masonzhang21/time-warden",
    body: (
      <p>
        <span style={styles.h}>
          A chrome extension that cracks down on distracting sites by slowly fading them away as you spend time on them.
        </span>{" "}
        Time Warden is highly customizable - you can configure how long it takes for sites disappear and reappear. It also keeps track of
        your total screen time!
      </p>
    ),
    technologies: ["React", "Javascript", "Chrome"],
  },
  acrossword: {
    iconName: "acrossword_icon.jpeg",
    title: "acrossword",
    titleStyle: { color: "#C77AFE" },
    color: "#C77AFE",
    github: "https://github.com/masonzhang21/acrossword",
    demo: "https://github.com/masonzhang21/acrossword",
    body: (
      <p>
        <span style={styles.h}>An iOS app for realtime collaborative crossword puzzles between friends.</span> Users can make an account to
        play crosswords from the NYT archives and invite other players to their game, with all data stored in the cloud. Acrossword comes
        with the whole suite of crosswording features including pencil mode, checking/revealing, autonavigation between clues, and more.
      </p>
    ),
    technologies: ["Swift", "Firebase", "Socket.IO", "Node.js", "Javascript"],
  },
  bestaurant: {
    iconName: "bestaurant_icon.png",
    title: "Bestaurant",
    titleStyle: { fontFamily: "nunito", margin: 0, color: "#E35516", display: "flex", alignItems: "center" },
    color: "#ffa245",
    github: "https://github.com/masonzhang21/bestaurant",
    demo: "https://bestaurant.masonzee.com/",
    body: (
      <p>
        <span style={styles.h}>A React app to help you and a group of people pick a restaurant.</span> Bestaurant lets your group select
        some preliminary filters (like price, cuisine, distance, etc) and runs an algorithm to pick 5 great restaurants nearby. It then has
        everybody vote on their favorite place and tallies up the scores, automatically updating the winning restaurant in realtime.
      </p>
    ),
    technologies: ["React", "Firebase", "Node.js"],
  },
  wikiguessr: {
    iconName: "wikiguessr_icon.png",
    title: "Wikiguessr",
    titleStyle: { fontFamily: "Verdana, Geneva, Tahoma, sans-serif", margin: 0, color: "#343A3F", display: "flex", alignItems: "center" },
    color: "#737373",
    github: "https://github.com/masonzhang21/wikiguessr",
    demo: "https://wikiguessr.masonzee.com/",
    body: (
      <p>
        <span style={styles.h}>A web-based trivia game where you try to guess words based on their Wikipedia article.</span> Wikiguessr
        chooses a random Wikipedia article through a RESTful API and removes all traces of the article title. The player gets to reveal
        three sections for clues on what the article title is. This was my first CS project!
      </p>
    ),
    technologies: ["Javascript", "JQuery", "Node.js"],
  },
};
export default Radium(React.forwardRef(Projects));
