import React, { useState, useRef, useEffect, useMemo } from "react";
import "./App.css";
import Landing from "./Pages/Landing";
import Projects from "./Pages/Projects";
import Aboutme from "./Pages/Aboutme";
import Blog from "./Pages/Blog";
import Navbar from "./Components/Navbar";
import Radium, {StyleRoot} from "radium";
import * as C from "./Util/constants";
import { calculateGradientColor } from "./Util/utils";
import throttle from "lodash/throttle";
import Experience from "./Pages/Experience";
import { useMediaQuery } from "react-responsive";

/**
 * Plain Ol' Javascript Object
 */
class SectionInfo {
  constructor(name, color, ref) {
    this.name = name;
    this.color = color;
    this.ref = ref;
    this.percentViewport = null; // perent of the total viewport being taken up by the element: [0, 100]
    this.percentVisible = null; // percent of the total element that's currently visisble in the viewport: [0, 100]
    this.offsetTop = 0;
    this.elementHeight = 0;
  }
}

function App() {
  const [backgroundColor, setBackgroundColor] = useState(C.sectionTemplates["landing"].color.bg);
  const [upperProgressBarGradient, setUPBG] = useState();
  const [lowerProgressBarGradient, setLPBG] = useState();
  const [height, setHeight] = useState(0);


  // Create section infos and memoize so refs don't get repeatedly generated
  const sections = useMemo(() => {
    const sections = {};
    for (const [sectionId, sectionTemplate] of Object.entries(C.sectionTemplates)) {
      const ref = React.createRef();
      sections[sectionId] = new SectionInfo(sectionId, sectionTemplate.color, ref);
    }
    return sections;
  }, []);

  // Recalculate progress bars and background color every 20ms
  const onScroll = () => {
    const upperSections = [];
    const lowerSections = [];
    const visibleSections = [];
    // Recalculate percentViewport/percentVisible for each section
    for (const [section, sectionInfo] of Object.entries(sections)) {
      if (!sectionInfo.ref.current) continue;
      const viewportHeight = window.innerHeight;
      const scrollTop = window.scrollY; // only changing variable
      const elementOffsetTop = sectionInfo.ref.current.offsetTop;
      const elementHeight = sectionInfo.ref.current.offsetHeight;
      // For background color
      let percentViewport;
      if (scrollTop < elementOffsetTop) {
        percentViewport = 1 - (elementOffsetTop - scrollTop) / viewportHeight;
      } else {
        percentViewport = (elementHeight + elementOffsetTop - scrollTop) / viewportHeight;
      }
      percentViewport = Math.max(0, Math.min(1, percentViewport));
      if (percentViewport > 0) {
        visibleSections.push([sectionInfo.color.bg, percentViewport]);
      }
      sections[section].percentViewport = percentViewport;
      // For navbar
      // const distance = scrollTop + viewportHeight - elementOffsetTop;
      // let percentVisible = (distance - 0.5 * viewportHeight) / elementHeight;
      // percentVisible = Math.round(percentVisible * 100);
      // percentVisible = Math.min(100, Math.max(0, percentVisible)); // squish between 0 and 100
      // if (percentVisible === 0) {
      //   lowerSections.push([section, 100]);
      // } else if (percentVisible === 100) {
      //   upperSections.push([section, 100]);
      // } else {
      //   upperSections.push([section, percentVisible]);
      //   lowerSections.push([section, 100 - percentVisible]);
      // }
    }
    // Update background color
    if (visibleSections.length === 1 && backgroundColor !== visibleSections[0][0]) {
      setBackgroundColor(visibleSections[0][0]);
    } else if (visibleSections.length === 2) {
      let [section1Color, section1Percentage] = visibleSections[0];
      const [section2Color, section2Percentage] = visibleSections[1];

      if (section1Percentage > 0.96) {
        section1Percentage = 100; // QUICK FIX FOR BUG
      }
      const newColor = calculateGradientColor(section1Color.slice(1), section2Color.slice(1), section1Percentage);
      setBackgroundColor(`#${newColor}`);
    }
    // // Update navbar
    // const calculateProgressBarGradient = (sectionPercentages) => {
    //   const progressBarGradient = [];
    //   const totalPercent = sectionPercentages.reduce((a, b) => a + b[1], 0); //for normalizing; this can be > 100
    //   let fromPosition = 0;
    //   for (const [section, percentage] of sectionPercentages) {
    //     const toPosition = percentage / totalPercent + fromPosition;
    //     progressBarGradient.push(
    //       `${sections[section].color.nav} ${Math.round(fromPosition * 100)}%, ${sections[section].color.nav} ${Math.round(toPosition * 100)}%`
    //     );
    //     fromPosition = toPosition;
    //   }
    //   return progressBarGradient.join(",");
    // };
    // setUPBG(calculateProgressBarGradient(upperSections));
    // setLPBG(calculateProgressBarGradient(lowerSections));
  };
  
  const updateWindowDimensions = () => {
    // checks if mobile 
    const windowHeight = window.innerWidth < 768 ? window.outerHeight : window.innerHeight
    setHeight(windowHeight)
  }

  useEffect(() => {
    // adjust the number of milliseconds between repaints here
    window.addEventListener("scroll", throttle(onScroll, 40, { leading: true, trailing: true }));
    window.addEventListener("resize", throttle(updateWindowDimensions, 300, { leading: true, trailing: true }));
    onScroll();
    updateWindowDimensions();
  }, []);

  const isVertical = useMediaQuery({ query: "(min-width: 1170px)" });
  const collapseExperience = useMediaQuery({ query: "(max-width: 500px)" });


  return (
    <StyleRoot style={[styles.container]}>
      <div style={{ background: backgroundColor, width: "100%", height: height ? `${height}px` : "100%", position: "fixed", zIndex: -1 }}></div>
      <Navbar isVertical={isVertical} collapseExperience={collapseExperience} upbg={upperProgressBarGradient} lpbg={lowerProgressBarGradient}></Navbar>

      <div className="px-3 pb-3" style={{...styles.pages, maxWidth: "max(85%, 1000px)"}}>
        <Landing section="landing" ref={sections["landing"].ref} offsetNavbarHeight={!isVertical}></Landing>
        <Aboutme section="aboutme" ref={sections["aboutme"].ref}></Aboutme>
        <Projects section="projects" ref={sections["projects"].ref}></Projects>
        <Experience section="experience" ref={sections["experience"].ref}></Experience>
        <Blog section="blog" ref={sections["blog"].ref}></Blog>
      </div>
    </StyleRoot>
  );
}

var styles = {
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  pages: {
    height: "100%",
    width: "100%",
  },
};

export default Radium(App);
