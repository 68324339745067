export const minecraftDanceAnimations = { Belly: "", BreakdanceFreeze: "", Chicken: "", House: "", NorthernSoul: "", Robot: "", Samba: "" };
export const minecraftHelloAnimations = {
  Bow: "Hey! Let's craft stuff.",
  BreathingIdle: "Hey! Let's craft stuff.",
  ShakingHands: "Hey! Let's craft stuff.",
  SittingLaughing: "Said something funny?",
  Waving: "Hey! Let's craft stuff.",
  Bored: "", 
  Waving2: "", 
  HappyIdle: "", 
  HappyIdle1: ""
};
export const minecraftCodeAnimations = {
  Typing: "Hey! Let's craft stuff.",
}

// check Github history for the full list of animations
export const humanHelloAnimations = {
  Archery: "Mason takes aim at orcs during the Battle of Dagorlad (SA 3434)",
  BeingElectrocuted: "Mason discovers electricity in a lightning storm (1752)",
  Defeat: "Breaking prod :(",
  Drinking: "Stays hydrated...sort of",
  DrunkWalk: "Mason deals with the aftermath of a wild party",
  Falling: "Skydiving over Italy (circa 2016)",
  Gaming: "A three-day Minecraft marathon",
  GuitarPlaying: "Rocking out on my guitar",
  Idle: "Waiting for inspiration",
  Paddling: "Mason sets sail in search of new lands (1405)",
  QuickInformalBow: "Hi, I'm Mason!",
  Running: "Mason's hot and humid midsummer run",
  ShakingHands: "Hi, pleased to meet you. I'm Mason!",
  Singing: "Mason's tear-jerking performance on American Idol",
  Sleeping: "Mason's favorite activity",
  //StandingArguing: "Mason debates the universe's origin",
  ThoughtfulHeadNod: "Contemplating our simulated reality",
  Victory: "Mason wins at life",
  Waving: "Hi, pleased to meet you. I'm Mason!",
  Writing: "Mason speedwrites a last-minute essay (2021, Brown University)",
};
export const humanDanceAnimations = {
  "BboyHipHopMove": "",
  "BreakdanceEnding2": "",
  "Breakdancing": "",
  "BrooklynUprock": "",
  "Cartwheel": "",
  "HeadSpinning": "",
  "HipHopDancing": "",
  "RobotHipHopDance": "",
  "TwistDance": "",
}
export const humanCodeAnimations = {
  Typing: "Mason writes effortless, efficient, excellent code",
}

export const minecraftAnimationOrder = ["Waving", "ShakingHands", "Bow", "Bored", "HappyIdle",  "BreathingIdle"]




export const blue = { bg: "#a8dcff", nav: "#9cd4ff", selected: "#89c3f0" };
export const green = { bg: "#2a9d86", nav: "#2BA596", selected: "#1f8f82" };
export const purple = { bg: "#BDB2FF", nav: "#afa1ff", selected: "#9b8ced" };
export const orange = { bg: "#FFD6A5", nav: "#ffce91", selected: "#f5be7a" };
export const red = { bg: "#ff8f8f", nav: "#ff8585", selected: "#eb7373" };

export const centerFlex = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const sectionTemplates = {
  landing: {
    name: "hello :)",
    color: blue,
  },
  aboutme: {
    name: "about me",
    color: green,
  },
  projects: {
    name: "projects",
    color: purple,
  },
  experience: {
    name: "experience",
    color: orange,
  },
  blog: {
    name: "blog",
    color: red,
  },
};
