import React from "react";
import Radium from "radium";
import * as C from "../Util/constants";

function Sider(props) {
  const generateSectionBox = ([sectionId, sectionTemplate]) => {
    if (props.collapseExperience && sectionId === "experience") {
      sectionTemplate.name = "work";
    }
    return (
      <a
        className="p-1"
        key={sectionId}
        href={`#${sectionId}`}
        style={[
          styles.box,
          {
            backgroundColor: sectionTemplate.color.nav,
            ":hover": { border: `3px solid ${sectionTemplate.color.selected}` },
            ":active": { backgroundColor: sectionTemplate.color.selected },
          },
        ]}
      >
        {sectionTemplate.name}
      </a>
    );
  };
  const sectionBoxes = Object.entries(C.sectionTemplates).map(generateSectionBox);
  const gradientDirection = props.isVertical ? "bottom" : "right";
  return (
    <div style={[styles.container, props.isVertical ? styles.containerVertical : styles.containerHorizontal]}>
      {/* <div
        style={[
          styles.bar,
          { background: `linear-gradient(to ${gradientDirection}, ${props.upbg})`, marginBottom: `-${outlineThickness}` },
          props.isVertical ? { width: "15px" } : { height: "15px" },
        ]}
      ></div> */}
      <div style={styles.boxesOuter}>
        <div style={[styles.boxesInner, { flexDirection: props.isVertical ? "column" : "row" }]}>{sectionBoxes}</div>
      </div>
      {/* <div
        style={[
          styles.bar,
          { background: `linear-gradient(to ${gradientDirection}, ${props.lpbg})`, marginTop: `-${outlineThickness}` },
          props.isVertical ? { width: "15px" } : { height: "15px" },
        ]}
      ></div> */}
    </div>
  );
}

const outlineColor = "white";
var outlineThickness = "3px";
var styles = {
  container: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  containerHorizontal: {
    top: "1%",
    flexDirection: "row",
  },
  containerVertical: {
    left: "1%",
    flexDirection: "column",
  },
  boxesOuter: {
    backgroundImage: `linear-gradient(${outlineColor}, ${outlineColor}), linear-gradient(${outlineColor}, ${outlineColor}), linear-gradient(${outlineColor}, ${outlineColor}), linear-gradient(${outlineColor}, ${outlineColor})`,
    backgroundPosition: "top left, top right, bottom left, bottom right",
    backgroundRepeat: "no-repeat",
    backgroundSize: `20px ${outlineThickness}`,
    boxShadow: `-${outlineThickness} 0 0 0 ${outlineColor}, ${outlineThickness} 0 0 0 ${outlineColor}`,
  },
  boxesInner: { display: "flex", margin: `${outlineThickness} 0 ${outlineThickness} 0px` },
  box: {
    width: "100px",
    height: "100px",
    maxWidth: "min(19vh, 19vw)",
    maxHeight: "min(19vh, 19vw)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1em",
    color: "black",
    textDecoration: "none",
    wordBreak: "break-word",
    textAlign: "center",
  },
  bar: {
    flex: 1,
    zIndex: 2,
  },
};
export default Radium(Sider);
