import React from "react";
import Radium from "radium";
import Title from "../Components/Title";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WorkIcon from "../Components/WorkIcon";
import TechnologiesList from "../Components/TechnologiesList";
import { Button } from "react-bootstrap";
import * as C from "../Util/constants";
function Experience(props, ref) {
  return (
    <div id={props.section} ref={ref} style={{ minHeight: "100%" }}>
      <Title title="Experience" subtitle="The annals of my professional career" section={props.section}></Title>

      <VerticalTimeline className="my-5" style={{ margin: 0 }}>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#333333", color: "white" }}
          contentArrowStyle={{ borderRight: "7px solid #333333" }}
          date="Fall 2023"
          icon={<WorkIcon imageName={"climax_logo.jpeg"} />}
        >
          <div style={{ color: "white" }}>
            <TechnologiesList section={"experience"} technologies={["Python", "SQL", "Javascript", "Dagster"]}></TechnologiesList>
            <h3 className="vertical-timeline-element-subtitle mt-3">
              Data Engineering Intern @ <b>Climax Foods</b>
            </h3>
            <h6 className="vertical-timeline-element-title mt-1">Berkeley, CA</h6>
            <ul className="mt-3">
              <li>Applied machine learning frameworks to develop plant-based cheeses at a venture-backed alt proteins startup</li>
              <li>Built data pipelines with cloud orchestration software for migration to a new data warehouse</li>
              <li>Designed a custom Javascript web app for scientists to log their experimental results</li>
            </ul>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#635AFF", color: "white" }}
          contentArrowStyle={{ borderRight: "7px solid  #635AFF" }}
          date="Summer 2022"
          icon={<WorkIcon imageName={"stripe_logo.svg"} />}
        >
          <div style={{ color: "white" }}>
            <TechnologiesList section={"experience"} technologies={["Python", "PyTorch", "SQL", "Differential Privacy"]}></TechnologiesList>
            <h3 className="vertical-timeline-element-subtitle mt-3">
              Software Engineering Intern @ <b>Stripe</b>
            </h3>
            <h6 className="vertical-timeline-element-title mt-1">Seattle, WA</h6>
            <ul className="mt-3">
              <li>
                Implemented a differentially private GAN to generate synthetic data to meet Stripe’s testing needs while keeping customer
                data private
              </li>
              <li>Contributed epsilon privacy guarantees to an open source library, the Synthetic Data Vault</li>
              <li>Evaluated internal synthetic data on several privacy and quality metrics</li>
            </ul>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#ffb84d" }}
          contentArrowStyle={{ borderRight: "7px solid  #ff9900" }}
          date="Summer 2022"
          icon={<WorkIcon imageName={"amazon_logo.jpeg"} />}
        >
          <div style={{ color: "black" }}>
            <TechnologiesList
              section={"experience"}
              technologies={["SQL", "Java", "Spring", "Hadoop", "Spark", "AWS", "Javascript", "JQuery"]}
            ></TechnologiesList>
            <h3 className="vertical-timeline-element-subtitle mt-3">
              Software Development Intern @ <b>Amazon</b>
            </h3>
            <h6 className="vertical-timeline-element-title mt-1">Seattle, WA</h6>
            <ul className="mt-3">
              <li>
                Designed & implemented a <span style={styles.h}>full-stack web application</span> with a Spring MVC backend
              </li>
              <li>
                Performed <span style={styles.h}>large-scale data manipulation</span> and ETL operations using the Hadoop framework{" "}
              </li>
              <li>Integrated several AWS services, including S3, DynamoDB, Elastic MapReduce, and Athena</li>
            </ul>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#182857", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #182857" }}
          date="Summer 2020"
          icon={<WorkIcon imageName={"inky_logo.jpeg"} />}
        >
          <div style={{ color: "white" }}>
            <TechnologiesList section={"experience"} technologies={["Tensorflow", "Keras", "Scikit-learn", "Python"]}></TechnologiesList>
            <h3 className="vertical-timeline-element-subtitle mt-3">
              Machine Learning Intern @ <b>INKY</b>
            </h3>
            <h6 className="vertical-timeline-element-title mt-1">College Park, MD</h6>
            <ul className="mt-3">
              <li>
                Implemented a <span style={styles.h}>Transformer-based neural network</span> that improved INKY’s phishing detection
                accuracy by 15%
              </li>
              <li>Trained a brand identifier model that predicted the logo image in an email at ~90% accuracy</li>
            </ul>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Spring 2020 - Spring 2021"
          dateClassName="timeline-date"
          contentStyle={{ background: "#3A1E1A", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #3A1E1A" }}
          icon={<WorkIcon imageName={"brown_logo.png"} />}
        >
          <div>
            <TechnologiesList
              section={"experience"}
              technologies={["React", "Javascript", "Docker", "Flask", "Python", "Tensorflow", "Linux"]}
            ></TechnologiesList>
            <h3 className="vertical-timeline-element-subtitle mt-3">
              CS Research Assistant @ <b>Serre Lab</b>
            </h3>
            <h6 className="vertical-timeline-element-title mt-1">Providence, RI</h6>
            <ul className="mt-3">
              <li>
                Contributed to the PaleoAI project, which aims to use computer vision to determine the taxonomic classification of
                fossilized leaves
              </li>
              <li>
                Created a <span style={styles.h}>full stack React/Flask app</span> that allows paleobotanists to upload their fossil photos
                and receive a classification within seconds
              </li>
              <li>
                Dockerized and deployed the application on a <span style={styles.h}>Linux server</span>
              </li>
            </ul>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Fall 2019 - Spring 2023"
          contentStyle={{ background: "#3A1E1A", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #3A1E1A" }}
          icon={<WorkIcon imageName={"brown_logo.png"} />}
        >
          <div>
            <h3 className="vertical-timeline-element-subtitle mt-3">
              B.A. in CS and Philosophy @ <b>Brown University</b>
            </h3>
            <h6 className="vertical-timeline-element-title mt-1">Providence, RI</h6>
            <ul className="mt-3">
              <li>
                <span style={styles.h}>GPA:</span> 4.00
              </li>
              <li>
                <span style={styles.h}>Teaching:</span> Undergraduate TA for Deep Learning (Fall 2021 & Spring 2022)
              </li>
            </ul>
            <ul className="mt-3">
              <li>
                <span style={styles.h}>Machine Learning Courses:</span> Advanced Topics in Deep Learning{" "}
                <span style={{ color: "gray" }}>[grad-level] </span>, Computer Vision, Machine Learning, Deep Learning
              </li>
              <li>
                <span style={styles.h}>CS Courses:</span> Privacy-Conscious Computer Systems{" "}
                <span style={{ color: "gray" }}>[grad-level] </span>, Distributed Systems, Computer Systems, Software Engineering,
                Functional Programming,
              </li>
              <li>
                <span style={styles.h}>Math Courses:</span> Discrete Structures and Probability, Linear Algebra, Statistical Inference
              </li>
            </ul>
            <ul className="mt-3">
              <li>
                <span style={styles.h}>Areas of Philosophical Interest:</span> Consciousness, Perception, Quantum Mechanics, Ethics, Plato
              </li>
              <li>
                <span style={styles.h}>Senior Thesis:</span> <i>A Non-Axiological Guide to Population Ethics</i>
              </li>
            </ul>
          </div>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

var sectionColors = C.sectionTemplates["experience"].color;
var styles = {
  header: {
    fontFamily: "Bungee Shade, cursive",
    color: "",
    textAlign: "center",
    width: "100%",
  },
  u: {
    textDecoration: `underline 2px solid ${sectionColors.bg}`,
  },
  h: {
    backgroundColor: sectionColors.bg,
    color: "black",
    padding: "0 3px 0 3px",
  },
};

export default Radium(React.forwardRef(Experience));
