import React, { useEffect, useRef, useState } from "react";
import { FreeCamera, Vector3, HemisphericLight, MeshBuilder, SceneLoader, ArcRotateCamera, Color4, Engine, Scene } from "@babylonjs/core";
import * as BABYLON from "@babylonjs/core";
import * as C from "../Util/constants";
import "@babylonjs/loaders";
import { random } from "lodash";

const scheduleAnimations = (scene, setCaption, mode, isMinecraft) => {
  let animations;
  if (mode === "dance" && isMinecraft) animations = C.minecraftDanceAnimations;
  else if (mode === "dance" && !isMinecraft) animations = C.humanDanceAnimations;
  else if (mode === "hello" && isMinecraft) animations = C.minecraftHelloAnimations;
  else if (mode === "hello" && !isMinecraft) animations = C.humanHelloAnimations;
  else if (mode === "code" && isMinecraft) animations = C.minecraftCodeAnimations;
  else if (mode === "code" && !isMinecraft) animations = C.humanCodeAnimations;
  else animations = C.minecraftHelloAnimations;
  let minecraftAnimations = [...C.minecraftAnimationOrder];
  const minAnimationTime = 4000;
  let startTime = 0;
  const animationLoop = () => {
    const endTime = Date.now();
    if (endTime - startTime > minAnimationTime) {
      // choose new animation
      const animationNames = Object.keys(animations);
      let animationName;
      if (isMinecraft && mode === "hello") {
        if (minecraftAnimations.length === 0) minecraftAnimations = [...C.minecraftAnimationOrder];
        animationName = minecraftAnimations.shift();
      } else if (mode === "code") {
        animationName = "Typing";
      } else {
        const randomAnimationName = animationNames[Math.floor(Math.random() * animationNames.length)];
        animationName = randomAnimationName;
      }
      const animation = scene.getAnimationGroupByName(animationName);
      // apply new animation
      animation.onAnimationGroupLoopObservable.add(animationLoop);
      scene.stopAllAnimations();
      animation.start(true, 1, animation.from, animation.to, false);
      setCaption(animations[animationName]);
      startTime = Date.now();
    }
  };
  animationLoop(true);
};

const onSceneReady = (scene, setCaption, mode, isMinecraft) => {
  scene.clearColor = new Color4(0, 0, 0, 0);
  scene.ambientColor = new BABYLON.Color3(1, 1, 1);

  const helper = scene.createDefaultEnvironment({ enableGroundShadow: true });
  helper.setMainColor(new BABYLON.Color3(1, 1, 1));

  scene.animationPropertiesOverride = new BABYLON.AnimationPropertiesOverride();
  scene.animationPropertiesOverride.enableBlending = true;
  scene.animationPropertiesOverride.blendingSpeed = 0.04;
  scene.animationPropertiesOverride.loopMode = 1;

  // x: shift circle left/right y: shift circle up/down z: shift circle into/out of the page
  var camera = new ArcRotateCamera("camera", Math.PI / 2, Math.PI / 2.2, 2, new Vector3(0, 1.2, 1), scene);
  camera.lowerRadiusLimit = 0;
  camera.upperRadiusLimit = 1000;
  camera.minZ = 0;

  // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
  var light1 = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
  light1.intensity = 0.7;
  var light = new BABYLON.PointLight("PointLight", new BABYLON.Vector3(0, 5, 5), scene);
  light.intensity = 5;

  var shadowGenerator = new BABYLON.ShadowGenerator(1024, light);
  shadowGenerator.useBlurExponentialShadowMap = true;
  shadowGenerator.blurKernel = 32;

  // Load my character
  const modelFile = isMinecraft ? "minecraft.glb" : "human.glb";
  SceneLoader.ImportMesh(null, modelFile, "", scene, (newMeshes, particleSystems, skeletons, animationGroups) => {
    let character = newMeshes[0];
    shadowGenerator.addShadowCaster(character, true);
    scheduleAnimations(scene, setCaption, mode, isMinecraft);
  });
};

export default (props) => {
  const reactCanvas = useRef(null);
  const { antialias, engineOptions, adaptToDeviceRatio, sceneOptions } = {};
  useEffect(() => {
    if (reactCanvas.current) {
      const engine = new Engine(reactCanvas.current, true, engineOptions, true);
      const scene = new Scene(engine, sceneOptions);
      if (scene.isReady()) {
        onSceneReady(scene, props.setCaption, props.mode, props.isMinecraft);
      } else {
        scene.onReadyObservable.addOnce((scene) => {
          onSceneReady(scene, props.setCaption);
        });
      }

      engine.runRenderLoop(() => {
        scene.render();
      });

      return () => {
        scene.getEngine().dispose();
      };
    }
  }, [reactCanvas, props.mode, props.isMinecraft]);
  return <canvas ref={reactCanvas} style={props.style} />;
};
