import Radium from "radium";
import React from "react";
import { pulse } from 'react-animations';

function BlogItem(props) {
  const {category, title, date, link} = props.info;
  return (
    <a href={link} style={{cursor: "pointer", color: "black", textDecoration: "none"}}>
      <div
        className="p-4"
        style={[styles.bounce, {
          backgroundColor: "white",
          height: "250px",
          width: "200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "5px",
          textAlign: "center",
          boxShadow: "4px 8px 4px 4px rgba(74, 74, 74, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }]}
      >
        <h5>
          <b>
            <u>{category}</u>
          </b>
        </h5>
        <h4 style={{ textAlign: "center" }}>
          <i>{title}</i>
        </h4>
        <h6>{date}</h6>
      </div>
    </a>
  );
}

var styles = {
    bounce: {
        ":hover": {
            animation: 'x 1s',
            animationName: Radium.keyframes(pulse, 'bounce')
        }
      }
}

export default Radium(BlogItem)