import React from "react";
import { Button } from "react-bootstrap";
import { EnvelopeAt, FilePdf, Github, Linkedin } from "react-bootstrap-icons";
import Resume from "../assets/Resume.pdf";

export default function ExternalLinks() {
  return (
    <div className="" style={{ fontSize: "1rem", fontStyle: "normal" }}>
        <Button className="mx-2 linkbutton" size="sm" variant="outline-danger" href={Resume} style={{ color: "#8f0c00" }} target="_blank">
        <FilePdf style={{ color: "#8f0c00" }} /> 
      </Button>
      <Button className="mx-2 linkbutton" size="sm" variant="outline-success" href={"mailto:masonzhang21@gmail.com"} style={{ color: "green" }} target="_blank">
        <EnvelopeAt />
      </Button>
        <Button
        className="mx-2 linkbutton"
        size="sm"
        variant="outline-primary"
        href="https://linkedin.com/in/mz21"
        style={{ color: "#0A66C2" }}
        target="_blank"
      >
        <Linkedin style={{ color: "#0A66C2" }} />
      </Button>


      <Button
        className="mx-2 linkbutton"
        size="sm"
        variant="outline-dark"
        href="https://github.com/masonzhang21"
        style={{ color: "#292929" }}
        target="_blank"
      >
        <Github style={{ color: "#24292E" }} />
      </Button>

    </div>
  );
}
