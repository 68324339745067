import React, { useState, forwardRef, useEffect, useRef } from "react";
import ModelRenderer from "../Components/ModelRenderer";
import * as C from "../Util/constants";
import Radium from "radium";
import Title from "../Components/Title";
import Resume from "../assets/Resume.pdf";
import { Button, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import ExternalLinks from "../Components/ExternalLinks";
function Landing(props, ref) {
  const [caption, setCaption] = useState("Loading Mason...");
  const [mode, setMode] = useState("hello");
  const [isMinecraft, setIsMinecraft] = useState(true);

  const subtitleLinks = <div style={{fontStyle: "italic",}}>
    <p className="mb-3">Hey, let's craft stuff!</p>
              <ExternalLinks></ExternalLinks>


  </div>
  return (
    <div id={props.section} ref={ref} style={{ minHeight: "100%", width: "100%", paddingTop: props.offsetNavbarHeight ? "90px" : 0 }}>
      <Title title="MASON Z" subtitle={subtitleLinks} section={props.section} style={{ paddingBottom: 0 }}></Title>

      <div style={C.centerFlex}>
        <div className="mt-4" style={[C.centerFlex, styles.container]}>
          <ModelRenderer setCaption={setCaption} style={styles.model} mode={mode} isMinecraft={isMinecraft} />
          {/* <div className="mt-3" style={styles.caption}>
            <h5 style={{ padding: "10px", margin: 0, textAlign: "center", fontFamily: "PT Sans Caption, sans-serif" }}>{caption}</h5>
          </div> */}
          <div className="mt-1 d-grid" style={{width: "75%"}}>
            {/* <Button
              className="gradient"
              style={{ margin: "0 5px 0 0", borderColor: "white", animationPlayState: isDance ? "running" : "paused" }}
              size="lg"
              onClick={() => {
                setIsDance(!isDance);
              }}
            >
              DANCE MODE
            </Button> */}
      <ToggleButtonGroup type="radio" variant="success" name="options" defaultValue={"hello"} style={{  }} onChange={(value) => setMode(value)}>
      <ToggleButton id="tbg-radio-1" variant="dark" value={"hello"} style={{}} >
          Hello
        </ToggleButton>
         <ToggleButton
              className={mode === "dance" ? "mx-0 gradient" : "mx-1"}
              variant="dark"
              id="tbg-radio-2"
              value={"dance"}
              >
              DANCE MODE
            </ToggleButton>

        <ToggleButton id="tbg-radio-3" variant="dark" value={"code"} style={{}}>
          Code
        </ToggleButton>
      </ToggleButtonGroup>
            {/* <Button
              variant="light"
              style={{ margin: "0 5px 0 0", flex: 1, borderColor: "black" }}
              size="sm"
              onClick={() => {
                setIsMinecraft(!isMinecraft);
              }}
            >
              increase resolution
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

var styles = {
  header: {
    fontFamily: "Bungee Shade, cursive",
    textAlign: "center",
    width: "100%",
  },
  container: {
    width: "450px",
    maxWidth: "100%",
  },
  model: {
    width: "100%",
    height: "450px",
    maxHeight: "calc(100vw - 32px)", // 32px padding
    borderRadius: "50%",
    border: "10px solid black",
    pointerEvents: "none",
  },
  caption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "28px solid rgba(0, 0, 0, 0)",
    borderBottom: "28px solid white",
    borderTop: "28px solid white",
    height: 0,
    borderRadius: "10px",
    width: "100%",
  },
};
export default Radium(React.forwardRef(Landing));
