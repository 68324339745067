import React from "react";
import Radium from "radium"
import * as C from "../Util/constants"
import { Textfit } from "react-textfit";
function Title(props) {
  // Full-width child in limited-width parent correcting for scrollbar width (since vw includes scrollbar for some st00pid): 
  const scrollbarWidth = window.innerWidth - document.body.clientWidth
  const containerStyle = {
    marginLeft: `calc(50% - 50vw + ${scrollbarWidth/2}px)`,
    width: `calc(100vw - ${scrollbarWidth}px)`,
    backgroundColor: C.sectionTemplates[props.section].color.bg,

  }
  const headerStyle = {
    textAlign: "center",
    width: "100%",
    fontFamily: "Bungee Shade, cursive",
    borderRadius: "5px",
    
  };
  return (
    <div className="pb-3" style={[containerStyle]}>
      <Textfit className="px-3" style={headerStyle} mode="single">
        {props.title}
      </Textfit>
      <h4 className="mt-2 mx-3" style={{textAlign: "center", fontStyle: "italic" }}>{props.subtitle}</h4>
    </div>
  );
}

var styles = {

}

export default Radium(Title);
