import React from "react";
import * as C from "../Util/constants";
import Radium from "radium"
function ImgToggle(props) {
  return (
    <a
      className="clickable"
      style={styles.clickable}
      onMouseOver={() => {
        props.setImg([props.pId, props.imgName]);
      }}
      onMouseOut={() => {
        props.setImg(null);
      }}
    >
      {props.children}
    </a>
  );
}
var aboutmeColors = C.sectionTemplates["aboutme"].color;

var styles = {
  clickable: {
    textDecoration: `underline 2px solid`,
    textDecorationColor: aboutmeColors.bg,
    cursor: "pointer",
    ":hover": {
      backgroundColor: aboutmeColors.nav,
    },
    ":active": {
      backgroundColor: aboutmeColors.selected,
    },
  },
};

export default Radium(ImgToggle)
