import React, { useState } from "react";
import Radium from "radium";
import * as C from "../Util/constants";
import Title from "../Components/Title";
import ImgToggle from "../Components/ImgToggle";
function Aboutme(props, ref) {
  const [img, setImg] = useState();
  return (
    <div className="my-3" id={props.section} ref={ref} style={[styles.container]}>
      <Title title="About Me" subtitle="who am I? what am I doing? where do I come from?" section={props.section}></Title>
      <div style={styles.body} className="my-5">
        <div style={styles.text}>
          <p className="mb-4">
            Hey,{" "}
            <ImgToggle setImg={setImg} imgName={"mason.jpeg"} pId={1}>
              I’m Mason Zhang
            </ImgToggle>
            ! I like building things that make the world a better place. I graduated Brown University in 2023 with a degree in Computer
            Science and Philosophy.
          </p>
          <p className="my-4 linkbutton">
            In 2024, I'll be working on a biochar project in Tanzania and backpacking the{" "}
            <ImgToggle setImg={setImg} imgName={"pct.jpeg"} pId={1}>
              Pacific Crest Trail
            </ImgToggle>
            , a 2,650 mile hike from Mexico to Canada. Last fall, I interned at an alternative proteins startup to make vegan cheese actually
            taste good. I've also worked at Stripe and Amazon in software engineering positions.
          </p>
        </div>
        {img && img[0] === 1 && <img style={styles.picture} src={require(`../assets/${img[1]}`)}></img>}

        <div style={styles.text}>
          <p className="mb-4">
            As a thinking thing, I generate many thoughts per day. Most of these relate to mundane things like trying to remember what I had
            for lunch earlier. But some other topics I’ve been puzzling over recently include{" "}
            <ImgToggle setImg={setImg} imgName={"koko.jpeg"} pId={2}>
              the moral status of nonhuman animals
            </ImgToggle>
            ,{" "}
            <ImgToggle setImg={setImg} imgName={"voting_funny.jpeg"} pId={2}>
              problems with democracies
            </ImgToggle>
            , and{" "}
            <ImgToggle setImg={setImg} imgName={"r2d2.jpg"} pId={2}>
              robot qualia
            </ImgToggle>{" "}
            (or lack thereof). I wrote my senior thesis on population ethics, a branch of philosophy that deals with the moral
            considerations surrounding actions that affect the wellbeing and existence of people not yet born.
          </p>
          <p className="mt-4">
            After four years of study, these are my takes on philosophy's greatest questions: I think that conscious mental states supervene
            on but cannot be reduced to the physical. Morality is objective and operate something like a heavily-utility-prioritized
            deontology. The Stoics gave the best life advice, but the Epicureans were probably right. Regarding the ultimate nature of
            reality, Bohmian Mechanics is the most plausible theory but still probably wrong.
          </p>
        </div>
        {img && img[0] === 2 && <img style={styles.picture} src={require(`../assets/${img[1]}`)}></img>}

        <div style={styles.text}>
          Here are my book recs:
          <ul className="mt-2">
            <li>
              {" "}
              Song of Achilles <span style={styles.bookRec}>[mythology/queer romance]</span>
            </li>
            <li>
              {" "}
              The Stormlight Archive <span style={styles.bookRec}>[high fantasy]</span>
            </li>
            <li>
              {" "}
              Good Omens <span style={styles.bookRec}>[low fantasy]</span>
            </li>
            <li>
              {" "}
              Candide <span style={styles.bookRec}>[philosophical satire]</span>
            </li>
            <li>
              {" "}
              Animal Liberation <span style={styles.bookRec}>[sad]</span>
            </li>
            <li>
              {" "}
              The World for Sale <span style={styles.bookRec}>[capitalism is wild]</span>{" "}
            </li>
          </ul>
        </div>
        {img && img[0] === 3 && <img style={styles.picture} src={require(`../assets/${img[1]}`)}></img>}
      </div>
    </div>
  );
}

var sectionColors = C.sectionTemplates["aboutme"].color;

var styles = {
  container: {
    minHeight: "100%",
    width: "100%",
  },
  body: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
  text: {
    width: "700px",
    maxWidth: "100%",
    padding: "30px",
    backgroundColor: "white",
    boxShadow: "4px 8px 4px 4px rgba(74, 74, 74, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  clickable: {
    textDecoration: `underline 2px solid ${sectionColors.bg}`,
    cursor: "pointer",
    ":hover": {
      backgroundColor: sectionColors.nav,
    },
    ":active": {
      backgroundColor: sectionColors.selected,
    },
  },
  picture: {
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "250px",
    border: "5px solid white",
    boxShadow: "4px 8px 4px 4px rgba(74, 74, 74, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  bookRec: {
    color: "#2BA596",
  },
};

export default Radium(React.forwardRef(Aboutme));
