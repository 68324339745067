import React from "react";
import * as C from "../Util/constants"
export default function TechnologiesList(props) {
  var styles = {
    technologies: {
      display: "flex",
      gap: "10px",
      flexWrap: "wrap"
    },
    technologyItem: {
      backgroundColor: C.sectionTemplates[props.section].color.bg,
      minWidth: "60px",
      textAlign: "center",
      margin: 0,
      color: "black"
    },
  };
  const createTechnologyElement = (tech, idx) => (
    <p key={idx} className="py-1 px-3" style={styles.technologyItem}> 
      {tech}
    </p>
  );
  return <div style={styles.technologies}>{props.technologies.map(createTechnologyElement)}</div>;
}
