import React from "react";
import Radium from "radium";
import Title from "../Components/Title";
import BlogItem from "../Components/BlogItem";

function Blog(props, ref) {
  
  const popEthics = {
    category: "Philosophy",
    title: "A Non-Axiologist's Guide to Population Ethics",
    date: "May 14, 2023",
    link: "https://docs.google.com/document/d/1pWlhVQs31BrG6DLio58wXdQftQeh18vkz9hN5X_kZR8/edit?usp=sharing",
  };
  return (
    <div id={props.section} ref={ref} style={{ minHeight: "100%", width: "100%" }}>
      <Title title="Blog" subtitle="A collection of my most interesting ideas" section={props.section}></Title>
      <div className="my-5" style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <div style={{ width: "700px", maxWidth: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
        <BlogItem info={popEthics}></BlogItem>
      </div>
      <hr className="mx-3" style={{width: "90%"}}></hr>
      </div>
     
    </div>
  );
}

var styles = {};

export default Radium(React.forwardRef(Blog));
