/**
 * Note that ratio is the % of color1, between [0, 1]
 */
export const calculateGradientColor = (color1, color2, ratio) => {
  const hex = (x) => {
    x = x.toString(16);
    return x.length == 1 ? "0" + x : x;
  };
  const r = Math.ceil(parseInt(color1.substring(0, 2), 16) * ratio + parseInt(color2.substring(0, 2), 16) * (1 - ratio));
  const g = Math.ceil(parseInt(color1.substring(2, 4), 16) * ratio + parseInt(color2.substring(2, 4), 16) * (1 - ratio));
  const b = Math.ceil(parseInt(color1.substring(4, 6), 16) * ratio + parseInt(color2.substring(4, 6), 16) * (1 - ratio));
  const combinedColor = hex(r) + hex(g) + hex(b);
  return combinedColor;
};
